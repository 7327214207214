<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування новини" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabHead" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="item in tabs"
                  :key="item.name"
                >
                  {{item.name}}
                </va-tab>
              </va-tabs>
              <va-tabs v-model="tabChild" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="child in tabsChild"
                  :key="child"
                >
                  {{child}}
                </va-tab>
              </va-tabs>

              <div class="flex xs12" v-if="tabHead === 0 && tabChild === 0">
                <va-input
                  label="Header"
                  v-model="firstLogist.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-card class="mb-3" title="Image *">
                  <div v-if="firstLogistImgExisting">
                    <a style="display: block;" target="_blank" :href="firstLogistImgExisting">
                      <img style="max-width: 400px;" :src="firstLogistImgExisting">
                    </a>
                    <div><va-button small color="danger" @click="firstLogistImgExisting = deleteImage(firstLogistImgId) ? '' : firstLogistImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="firstLogistImg"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(firstLogistImg[0],'firstLogistImg')">Завантажити</va-button>
                </va-card>
                <va-input
                  label="link_name"
                  v-model="firstLogist.link_name"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="link_href"
                  v-model="firstLogist.link_href"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="sectionData title"
                  v-model="sectionData.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  type="textarea"
                  :minRows="4"
                  :autosize="true"
                  label="sectionData description"
                  v-model="sectionData.description"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="sectionData bigTitle"
                  v-model="sectionData.bigTitle"
                  :messages="['The recommended number of characters is 15']"
                />
                <div style="margin-bottom: 15px;">
                  <span>sectionData Content *</span>
                  <ckeditor v-model="sectionData.items" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
              </div>
              <div class="flex xs12" v-if="tabHead === 0 && tabChild === 1">
                <va-button :to="{ name: 'ms-counter', params: { field: 'firstCounter' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="firstCounterItemsTabFields"
                  :data="firstCounterItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'firstCounter' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabHead === 0 && tabChild === 2">
                <va-button :to="{ name: 'ms-counter', params: { field: 'rowCounter' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="firstCounterItemsTabFields"
                  :data="rowCounterItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'rowCounter' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>

              <div class="flex xs12" v-if="tabHead === 1 && tabChild === 0">
                <va-input
                  label="Header"
                  v-model="audiOdesa.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  type="textarea"
                  :minRows="4"
                  :autosize="true"
                  label="sectionData description"
                  v-model="audiOdesa.description"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-card class="mb-3" title="Image *">
                  <div v-if="audiOdesaImgExisting">
                    <a style="display: block;" target="_blank" :href="audiOdesaImgExisting">
                      <img style="max-width: 400px;" :src="audiOdesaImgExisting">
                    </a>
                    <div><va-button small color="danger" @click="audiOdesaImgExisting = deleteImage(audiOdesaImgId) ? '' : audiOdesaImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="audiOdesaImg"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(audiOdesaImg[0],'audiOdesaImg')">Завантажити</va-button>
                </va-card>
                <va-input
                  label="link_name"
                  v-model="audiOdesa.link_name"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="link_href"
                  v-model="audiOdesa.link_href"
                  :messages="['The recommended number of characters is 15']"
                />
              </div>
              <div class="flex xs12" v-if="tabHead === 1 && tabChild === 1">
                <va-button :to="{ name: 'ms-counter', params: { field: 'audiOdesa' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="firstCounterItemsTabFields"
                  :data="audiOdesaItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'audiOdesa' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabHead === 1 && tabChild === 2">
                <va-button :to="{ name: 'ms-counter', params: { field: 'rowCounter2' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="firstCounterItemsTabFields"
                  :data="rowCounter2Items"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'rowCounter2' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>

              <div class="flex xs12" v-if="tabHead === 2 && tabChild === 0">
                <va-input
                  label="Header"
                  v-model="autostrada.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-card class="mb-3" title="Image *">
                  <div v-if="autostradaImgExisting">
                    <a style="display: block;" target="_blank" :href="autostradaImgExisting">
                      <img style="max-width: 400px;" :src="autostradaImgExisting">
                    </a>
                    <div><va-button small color="danger" @click="autostradaImgExisting = deleteImage(autostradaImgId) ? '' : autostradaImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="autostradaImg"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(autostradaImg[0],'autostradaImg')">Завантажити</va-button>
                </va-card>
                <va-input
                  label="link_name"
                  v-model="autostrada.link_name"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="link_href"
                  v-model="autostrada.link_href"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  type="textarea"
                  :minRows="4"
                  :autosize="true"
                  label="Lider title"
                  v-model="lider.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <!--                <va-input-->
                <!--                  label="Header"-->
                <!--                  v-model="respons.title"-->
                <!--                  :messages="['The recommended number of characters is 15']"-->
                <!--                />-->
                <!--                <va-input-->
                <!--                  type="textarea"-->
                <!--                  :minRows="4"-->
                <!--                  :autosize="true"-->
                <!--                  label="Response description"-->
                <!--                  v-model="respons.description"-->
                <!--                  :messages="['The recommended number of characters is 15']"-->
                <!--                />-->
                <va-input
                  type="textarea"
                  :minRows="4"
                  :autosize="true"
                  label="Response bigTitle"
                  v-model="respons.bigTitle"
                  :messages="['The recommended number of characters is 15']"
                />
                <div style="margin-bottom: 15px;">
                  <span>Response *</span>
                  <ckeditor v-model="respons.items" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  type="textarea"
                  :minRows="4"
                  :autosize="true"
                  label="Response two bigTitle"
                  v-model="respons2.bigTitle"
                  :messages="['The recommended number of characters is 15']"
                />
                <div style="margin-bottom: 15px;">
                  <span>Response 2 *</span>
                  <ckeditor v-model="respons2.items" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
              </div>
              <div class="flex xs12" v-if="tabHead === 2 && tabChild === 1">
                <va-button :to="{ name: 'ms-counter', params: { field: 'lider' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="firstCounterItemsTabFields"
                  :data="liderItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'lider' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabHead === 2 && tabChild === 2">
                <va-button :to="{ name: 'ms-counter', params: { field: 'firstCounter3' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="firstCounterItemsTabFields"
                  :data="firstCounter3Items"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'firstCounter3' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabHead === 2 && tabChild === 3">
                <va-input
                  label="Header"
                  v-model="titleCounter.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-button :to="{ name: 'ms-counter', params: { field: 'titleCounter' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="firstCounterItemsTabFields"
                  :data="titleCounterItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'titleCounter' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>

              <div class="flex xs12" v-if="tabHead === 3 && tabChild === 0">
                <va-input
                  label="Header"
                  v-model="pak.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-card class="mb-3" title="Image *">
                  <div v-if="pakImgExisting">
                    <a style="display: block;" target="_blank" :href="pakImgExisting">
                      <img style="max-width: 400px;" :src="pakImgExisting">
                    </a>
                    <div><va-button small color="danger" @click="pakImgExisting = deleteImage(pakImgId) ? '' : pakImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="pakImg"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(pakImg[0],'pakImg')">Завантажити</va-button>
                </va-card>
                <va-input
                  label="link_name"
                  v-model="pak.link_name"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="link_href"
                  v-model="pak.link_href"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Header"
                  v-model="modern.bigTitle"
                  :messages="['The recommended number of characters is 15']"
                />
                <div style="margin-bottom: 15px;">
                  <span>Content *</span>
                  <ckeditor v-model="modern.items" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  type="textarea"
                  :minRows="4"
                  :autosize="true"
                  label="Response description"
                  v-model="modern.description"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Header"
                  v-model="modern.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <div style="margin-bottom: 15px;">
                  <span>Content *</span>
                  <ckeditor v-model="modern.text" :editor="editor" :config="editorConfig"></ckeditor>
                </div>

                <va-input
                  :autosize="true"
                  label="titleDesc bigTitle"
                  v-model="titleDesc.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  type="textarea"
                  :minRows="4"
                  :autosize="true"
                  label="titleDesc items"
                  v-model="titleDesc.description"
                  :messages="['The recommended number of characters is 15']"
                />

                <va-input
                  :autosize="true"
                  label="comand bigTitle"
                  v-model="comand.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  type="textarea"
                  :minRows="4"
                  :autosize="true"
                  label="comand items"
                  v-model="comand.description"
                  :messages="['The recommended number of characters is 15']"
                />

                <va-input
                  :autosize="true"
                  label="titleDesc bigTitle"
                  v-model="titleDesc2.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  type="textarea"
                  :minRows="4"
                  :autosize="true"
                  label="titleDesc items"
                  v-model="titleDesc2.description"
                  :messages="['The recommended number of characters is 15']"
                />
              </div>
              <div class="flex xs12" v-if="tabHead === 3 && tabChild === 1">
                <va-button :to="{ name: 'ms-counter', params: { field: 'cramb' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="firstCounterItemsTabFields"
                  :data="crambItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'crambItems' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabHead === 3 && tabChild === 2">
                <va-button :to="{ name: 'ms-counter', params: { field: 'cramb2' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="firstCounterItemsTabFields"
                  :data="cramb2Items"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'cramb2' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabHead === 3 && tabChild === 3">
                <va-button :to="{ name: 'ms-counter', params: { field: 'firstCounter2' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="firstCounterItemsTabFields"
                  :data="firstCounter2Items"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'firstCounter2' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>

              <div class="flex xs12" v-if="tabHead === 4 && tabChild === 0">
                <va-input
                  label="title"
                  v-model="bgk.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-card class="mb-3" title="Image *">
                  <div v-if="bgkImgExisting">
                    <a style="display: block;" target="_blank" :href="bgkImgExisting">
                      <img style="max-width: 400px;" :src="bgkImgExisting">
                    </a>
                    <div><va-button small color="danger" @click="bgkImgExisting = deleteImage(bgkImgId) ? '' : bgkImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="bgkImg"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(bgkImg[0],'bgkImg')">Завантажити</va-button>
                </va-card>
                <va-input
                  label="link_name"
                  v-model="bgk.link_name"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="link_href"
                  v-model="bgk.link_href"
                  :messages="['The recommended number of characters is 15']"
                />                <va-input
                  type="textarea"
                  :minRows="4"
                  :autosize="true"
                  label="Response description"
                  v-model="mutant.text"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Header"
                  v-model="mutant.bigTitle"
                  :messages="['The recommended number of characters is 15']"
                />
                <div style="margin-bottom: 15px;">
                  <span>Content *</span>
                  <ckeditor v-model="mutant.items" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  type="textarea"
                  :minRows="4"
                  :autosize="true"
                  label="Response description"
                  v-model="mutant.description"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Header"
                  v-model="lastCounter.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Header"
                  v-model="product.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Header"
                  v-model="product.paragraph"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  :autosize="true"
                  :minRows="4"
                  :messages="['The recommended number of characters is 15']"
                  v-model="tehnik.bigTitle"
                  type="textarea"
                  label="Response description"
                />

                <div style="margin-bottom: 15px;">
                  <span>Content *</span>
                  <ckeditor v-model="tehnik.items" :editor="editor" :config="editorConfig"></ckeditor>
                </div>

              </div>
              <div class="flex xs12" v-if="tabHead === 4 && tabChild === 1">
                <va-button :to="{ name: 'ms-counter', params: { field: 'lastCounter' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="firstCounterItemsTabFields"
                  :data="lastCounterItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'lastCounter' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabHead === 4 && tabChild === 2">
                <va-button :to="{ name: 'ms-counter', params: { field: 'product' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="firstCounterItemsTabFields"
                  :data="productItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'product' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>

              <va-button @click="submit()">Save</va-button>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      firstLogist: {
        title: '',
        img: '',
      },
      firstCounter: [],
      sectionData: {
        title: '',
        description: '',
        bigTitle: '',
        items: '',
      },

      audiOdesaItems: {
        title: '',
        img: '',
        description: '',
      },

      autostrada: {
        title: '',
        img: '',
      },
      lider: {
        title: '',
      },
      respons: {
        title: '',
        description: '',
        bigTitle: '',
        items: '',
      },
      respons2: {
        title: '',
        description: '',
        bigTitle: '',
        items: '',
      },
      titleCounter: {
        title: '',
      },

      pak: {
        title: '',
        img: '',
      },
      modern: {
        bigTitle: '',
        items: '',
        description: '',
        title: '',
        text: '',
      },
      titleDesc: {
        title: '',
        description: '',
      },
      comand: {
        title: '',
        description: '',
      },
      titleDesc2: {
        title: '',
        description: '',
      },

      bgk: {
        title: '',
        img: '',
      },
      mutant: {
        text: '',
        description: '',
        bigTitle: '',
        items: '',
      },
      lastCounter: {
        title: '',
      },
      product: {
        title: '',
        paragraph: '',
      },
      tehnik: {
        bigTitle: '',
        items: '',
      },

      firstLogistImg: [],
      firstLogistImgId: '',
      firstLogistImgNew: '',
      firstLogistImgExisting: '',

      audiOdesaImg: [],
      audiOdesaImgId: '',
      audiOdesaImgNew: '',
      audiOdesaImgExisting: '',

      autostradaImg: [],
      autostradaImgId: '',
      autostradaImgNew: '',
      autostradaImgExisting: '',

      pakImg: [],
      pakImgId: '',
      pakImgNew: '',
      pakImgExisting: '',

      bgkImg: [],
      bgkImgId: '',
      bgkImgNew: '',
      bgkImgExisting: '',

      firstCounterItems: [],
      rowCounterItems: [],
      audiOdesa: [],
      rowCounter2Items: [],
      liderItems: [],
      titleCounterItems: [],
      firstCounter3Items: [],
      crambItems: [],
      cramb2Items: [],
      firstCounter2Items: [],
      lastCounterItems: [],
      productItems: [],

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_MS_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      errors: [],

      tabTitles: [
        '1LK',
        '1LK Items',
        '1LK Counter',
        'Audi',
        'Audi items',
        'Audi Counter',
        'Autostrada',
        'Autostrada lider',
        'Autostrada Items',
        'PAK',
        'PAK cramb 1',
        'PAK cramb 2',
        'PAK Counter',
        'BGK',
        'BGK Counter',
        'BGK products',
      ],
      tabValue: 0,
      tabHead: 0,
      tabChild: 0,
      tabsChild: ['Main', 'Items', 'Counter'],
      tabs: [
        { name: '1LK', items: ['Main', 'Items', 'Counter'] },
        { name: 'Audi', items: ['Main', 'Items', 'Counter'] },
        { name: 'Autostrada', items: ['Main', 'Lider', 'Counter', 'Items'] },
        { name: 'PAK', items: ['Main', 'Cramb1', 'Cramb2', 'Counter'] },
        { name: 'BGK', items: ['Main', 'Counter', 'Products'] },
      ],
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    uploadImage (file, field) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          if (field === 'firstLogistImg') {
            this.firstLogistImgNew = response.data.patch
            this.firstLogist.img = response.data.patch
            this.firstLogistImgExisting = `${process.env.VUE_APP_MS_DEV_URL}/${this.imgNew}`
            this.firstLogistImgId = response.data.id
          }
          if (field === 'audiOdesaImg') {
            this.audiOdesaImgNew = response.data.patch
            this.audiOdesa.img = response.data.patch
            this.audiOdesaImgExisting = `${process.env.VUE_APP_MS_DEV_URL}/${this.imgNew}`
            this.audiOdesaImgId = response.data.id
          }
          if (field === 'autostradaImg') {
            this.autostradaImgNew = response.data.patch
            this.autostrada.img = response.data.patch
            this.autostradaImgExisting = `${process.env.VUE_APP_MS_DEV_URL}/${this.imgNew}`
            this.autostradaImgId = response.data.id
          }
          if (field === 'pakImg') {
            this.pakImgNew = response.data.patch
            this.pak.img = response.data.patch
            this.pakImgExisting = `${process.env.VUE_APP_MS_DEV_URL}/${this.imgNew}`
            this.pakImgId = response.data.id
          }
          if (field === 'bgkImg') {
            this.bgkImgNew = response.data.patch
            this.bgk.img = response.data.patch
            this.bgkImgExisting = `${process.env.VUE_APP_MS_DEV_URL}/${this.imgNew}`
            this.bgkImgId = response.data.id
          }

          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/media/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
    },
    async removeCounter (id) {
      return axios.delete(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/counter/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
          this.fetch()
        })
    },
    submit () {
      const data = {
        main: {
          firstLogist: this.firstLogist,
          sectionData: this.sectionData,
          audiOdesa: this.audiOdesa,
          autostrada: this.autostrada,
          lider: this.lider,
          titleCounter: this.titleCounter,
          respons: this.respons,
          respons2: this.respons2,
          pak: this.pak,
          modern: this.modern,
          titleDesc: this.titleDesc,
          comand: this.comand,
          titleDesc2: this.titleDesc2,
          bgk: this.bgk,
          mutant: this.mutant,
          lastCounter: this.lastCounter,
          product: this.product,
          tehnik: this.tehnik,
        },
      }

      axios.put(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/companies`, data)
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/companies`)
        .then(response => {
          this.firstLogist = response.data.firstLogist
          this.sectionData = response.data.sectionData
          this.audiOdesa = response.data.audiOdesa
          this.autostrada = response.data.autostrada
          this.respons = response.data.respons
          this.respons2 = response.data.respons2
          this.lider = response.data.lider
          this.titleCounter = response.data.titleCounter
          this.pak = response.data.pak
          this.modern = response.data.modern
          this.titleDesc = response.data.titleDesc
          this.comand = response.data.comand
          this.titleDesc2 = response.data.titleDesc2
          this.bgk = response.data.bgk
          this.mutant = response.data.mutant
          this.lastCounter = response.data.lastCounter
          this.product = response.data.product
          this.tehnik = response.data.tehnik
          this.firstCounterItems = response.data.firstCounterItems
          this.rowCounterItems = response.data.rowCounterItems
          this.audiOdesaItems = response.data.audiOdesaItems
          this.rowCounter2Items = response.data.rowCounter2Items
          this.liderItems = response.data.liderItems
          this.titleCounterItems = response.data.titleCounterItems
          this.firstCounter3Items = response.data.firstCounter3Items
          this.crambItems = response.data.crambItems
          this.cramb2Items = response.data.cramb2Items
          this.firstCounter2Items = response.data.firstCounter2Items
          this.lastCounterItems = response.data.lastCounterItems
          this.productItems = response.data.productItems

          this.firstLogistImgExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + response.data.firstLogist.img
          this.audiOdesaImgExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + response.data.audiOdesa.img
          this.autostradaImgExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + response.data.autostrada.img
          this.pakImgExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + response.data.pak.img
          this.bgkImgExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + response.data.bgk.img
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
  computed: {
    locale () {
      return Vue.i18n.locale()
    },
    firstCounterItemsTabFields () {
      return [
        {
          name: 'label',
          title: 'Label',
          width: '40%',
        },
        {
          name: 'description',
          title: 'Description',
          width: '40%',
        },
        {
          name: 'additional_desc',
          title: 'Additional desc',
          width: '40%',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
    tabHead: function (value) {
      this.tabChild = 0
      this.tabsChild = this.tabs[value].items
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
